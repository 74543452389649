/* src/App.css */  
.App {  
  font-family: Arial, sans-serif;  
  padding: 20px;  
}  
  
h1 {  
  font-size: 2em;  
  color: #0c0568;  
}  
  
h2 {  
  font-size: 1.5em;  
  color: #435cdd;  
}  
  
p {  
  font-size: 1em;  
  color: #777;  
  margin-bottom: 20px;  
}  
  
.data-sections {  
  display: flex;  
  justify-content: space-between;  
}  
  
.section {  
  width: 30%;  
}  
  
.section h3 {  
  font-size: 1.2em;  
  color: #333;  
}  
  
.section div {  
  margin-bottom: 10px;  
}  
  
.section a {  
  color: #1a0dab;  
  text-decoration: none;  
}  
  
.section a:hover {  
  text-decoration: underline;  
}  